import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Link from './Link'
 
const CtaMobile = ({ lightTheme }) => {
 return (
   <StaticQuery
     query={graphql`
       query {
         imgLight: file(relativePath: { regex: "/cto_mobile_light_bg.png/" }) {
           childImageSharp {
             fluid(quality: 85) {
               src
             }
           }
         }
         img: file(relativePath: { regex: "/cto_mobile_bg.png/" }) {
           childImageSharp {
             fluid(quality: 85) {
               src
             }
           }
         }
       }
     `}
     render={({ imgLight, img }) => {
       const image = lightTheme
         ? imgLight.childImageSharp.fluid.src
         : img.childImageSharp.fluid.src
       return (
         <section
           id="cta-mobile"
           className={`cto ${lightTheme ? 'bg-faded' : 'bg-dark'}`}
         >
           <div className="container">
             <div className="row">
               <div className="col-12 col-sm-4 col-lg-2">
                 <img src={image} alt="Mobile Apps" className="cta-image" />
               </div>
               <div className="col-12 col-sm-8 col-lg-10">
                 <div className="row">
                   <div className="col-md-12">
                     <h3>Créez une application mobile en 3 étapes faciles</h3>
                   </div>
                 </div>
                 <div className="row">
                   <div className="col-sm-6 col-lg-3">
                     <div className="step">
                       <span className="counter">1</span>
                       <h4>Spécification</h4>
                       <p className="text-muted m-0">
                         communiquez votre spécification à notre analyste commercial
                       </p>
                     </div>
                   </div>
                   <div className="col-sm-6 col-lg-3">
                     <div className="step">
                       <span className="counter">2</span>
                       <h4>Développement</h4>
                       <p className="text-muted m-0">
                         programmation, conception,
                         <br />
                         mis en œuvre et tests
                       </p>
                     </div>
                   </div>
                   <div className="col-sm-6 col-lg-3 mt-sm-4 mt-md-0">
                     <div className="step">
                       <span className="counter">3</span>
                       <h4>Publication
</h4>
                       <p className="text-muted m-0">
                         votre application est envoyée dans
                         <br />
                         l’App Store et le Play Store
                       </p>
                     </div>
                   </div>
                   <div className="col-sm-6 col-lg-3 mt-sm-4 mt-md-0">
                     <div className="step">
                       <h4>Cela vous a intéressé?</h4>
                       <Link to="/estimate-app-cost/" className="btn btn-outline-warning">
                         Obtenez une estimation!
                       </Link>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </section>
       )
     }}
   />
 )
}
 
export default CtaMobile